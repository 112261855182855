import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Field from './Field'
import { withTranslation } from 'react-i18next'
import { LOCAL_STORAGE_KEYS } from '../../constants'
import { getToken } from '../../helpers'
import actionCodes from '../../context/actionCodes'
import {
  StyledTitleContainer,
  StyledTitle,
  StyledDesc,
  StyledGroupContainer,
} from '../styles'
import { formatNumber, formatDate } from '../../helpers/form'

interface DataformProps {
  formikRef?: React.MutableRefObject<{}>
  round?: any
  form?: any
  code?: string
  initialValues?: any
  yupSchema?: any
  title?: string
  description?: string
  caseId?: string
  flowId?: string
  flowInstanceId?: string
  t?: any
  shouldCache?: boolean
}

const Dataform: React.FC<DataformProps> = (props) => {
  const {
    form: { groups },
    code,
    initialValues,
    yupSchema,
    title,
    description,
    caseId,
    flowId,
    flowInstanceId,
    t,
    shouldCache,
  } = props

  if (!groups || groups.length === 0) {
    return t([
      'errors.genericErrorMessage',
      'errors.genericNoGroupsErrorMessage',
      '',
    ])
  }

  const groupRefs = groups.map((group) => ({
    title: group.title,
    ref: React.createRef(),
  }))

  // function handleNavigate(group) {
  //   const el = group.ref.current

  //   window.scrollTo({
  //     behaviour: 'smooth',
  //     left: 0,
  //     top: el.offsetTop - 20,
  //   })
  // }

  return (
    <React.Fragment>
      {(title || description) && (
        <StyledTitleContainer>
          {title && <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />}
          {description && (
            <StyledDesc dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </StyledTitleContainer>
      )}

      <Formik
        key={`form-${code}`}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape(yupSchema)}
        onSubmit={() => console.log('Submited')}
        validateOnMount={true}
      >
        {(formik) => {
          props.formikRef.current[code] = formik
          return (
            <form onSubmit={formik.handleSubmit}>
              {groups.map((group, idx) => {
                const { title, description } = group
                return (
                  <StyledGroupContainer key={idx} ref={groupRefs[idx].ref}>
                    {(title || description) && (
                      <StyledTitleContainer>
                        {title && (
                          <StyledTitle
                            dangerouslySetInnerHTML={{ __html: title }}
                          />
                        )}
                        {description && (
                          <StyledDesc
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        )}
                      </StyledTitleContainer>
                    )}
                    {group.fields.length > 0 && (
                      <div>
                        {group.fields.map((field, idx: React.Key) => {
                          let fieldValue: string | boolean = ''

                          if (field.calculated) {
                            if (actionCodes.isTouched(field.name)) {
                              fieldValue = actionCodes.getValue(
                                caseId,
                                code,
                                field.name
                              )
                            } else {
                              fieldValue = actionCodes.replaceValue(
                                caseId,
                                field.value
                              )

                              if (field.type === '5' || field.type === '6') {
                                fieldValue =
                                  fieldValue !== ''
                                    ? formatNumber(props.round, fieldValue)
                                    : ''
                              }

                              if (field.type === '2') {
                                fieldValue = !!fieldValue
                              }

                              if (field.type === '4') {
                                const cached = getToken(
                                  LOCAL_STORAGE_KEYS.CACHE
                                )?.[caseId]?.[flowId]?.[flowInstanceId]?.[
                                  code
                                ]?.[field.name]
                                fieldValue = cached
                                  ? cached
                                  : fieldValue !== ''
                                  ? formatDate(fieldValue).toISOString()
                                  : ''
                              }
                              actionCodes.addCode(
                                caseId,
                                code,
                                field.name,
                                fieldValue
                              )
                            }
                          } else {
                            fieldValue = formik.values[field.name]
                          }
                          return (
                            <Field
                              {...field}
                              key={idx}
                              id={caseId + '-' + field.name}
                              label={field.title}
                              options={field.values}
                              value={fieldValue}
                              formik={formik}
                              code={code}
                              caseId={caseId}
                              flowId={flowId}
                              flowInstanceId={flowInstanceId}
                              shouldCache={shouldCache}
                            />
                          )
                        })}
                      </div>
                    )}
                  </StyledGroupContainer>
                )
              })}
            </form>
          )
        }}
      </Formik>

      {/* {groupRefs.length > 0 && (
        <div className="section-menu">
          {groupRefs.map((gr) => (
            <button onClick={() => handleNavigate(gr)}>{gr.title}</button>
          ))}
        </div>
      )} */}
    </React.Fragment>
  )
}

export default withTranslation()(Dataform)
