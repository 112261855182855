import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { ASSETS_URL, DEFAULT_LANGUAGE } from './constants'

// const userLanguage = window.navigator.language

// const defaultLang: string =
// localStorage.getItem(LOCAL_STORAGE_KEYS.LANG) || DEFAULT_LANGUAGE
document.documentElement.lang = DEFAULT_LANGUAGE

i18n.use(Backend).init({
  fallbackLng: DEFAULT_LANGUAGE.replace('-', '_'),
  backend: {
    loadPath: ASSETS_URL + `/locales/{{lng}}/translation.json`,
    crossDomain: true,
  },
  interpolation: {
    escapeValue: false,
  },
  debug: false,
})

export default i18n
