import { Route } from 'react-router-dom'
import { isLoggedIn } from './helpers'
import { authService } from './services'

const ForceLogoutRoute = ({ component: Component, config, ...rest }) => {
  const { auth, logout } = authService
  if (isLoggedIn(auth)) {
    logout()
  }

  return (
    <Route
      {...rest}
      exact
      render={(props) => <Component config={config} {...props} />}
    />
  )
}

export default ForceLogoutRoute
