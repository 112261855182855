import React from 'react'
import { StyledFieldSelect, StyledSelectContainer } from '../../styles'
import { ReactComponent as Arrow } from '../../../theme/icons/forward.svg'
import styled, { css } from 'styled-components'
import { ListFieldProps } from '.'

const StyledArrow = styled(Arrow)<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled
      ? css`
          path {
            stroke: var(--pages-mainContainer-secondaryTextColor);
          }
        `
      : ``}
`

const Dropdown: React.FC<ListFieldProps> = ({
  formattedName,
  error,
  handleCustomBlur,
  handleCustomChange,
  readOnly,
  value,
  options,
  style,
  ...rest
}) => {
  return (
    <StyledSelectContainer>
      <StyledFieldSelect
        {...rest}
        name={formattedName}
        error={error}
        onBlur={(e) => handleCustomBlur(e, true)}
        onChange={(e) => handleCustomChange(e, true)}
        disabled={readOnly}
        value={value}
      >
        <option key="empty-option" value="" />
        {options &&
          options.map((option) => (
            <option
              key={option.key}
              value={option.key}
              label={option.value}
              selected={option.selected}
            >
              {option.value}
            </option>
          ))}
        <optgroup label=""></optgroup>
      </StyledFieldSelect>
      <StyledArrow disabled={readOnly} />
    </StyledSelectContainer>
  )
}

export default Dropdown
