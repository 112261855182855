import React, { useEffect, useState } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import useQuery from '../helpers/useQuery'
import {
  Caption,
  Heading,
  LinkButton,
  StyledFlexColumn,
} from '../components/commonStyles'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import * as Yup from 'yup'
import { LOCAL_STORAGE_KEYS, OTPLoginMethods, ROUTES } from '../constants'
import { getErrorMessage, isLoggedIn } from '../helpers'
import { authService } from '../services'
import { useStore } from '../context'
import { unsetLayout } from '../context/actionReducer'
import { RotatingLines } from 'react-loader-spinner'
import 'react-phone-input-2/lib/material.css'
import { PortalConfig } from '../helpers/useConfig'
import { LoginTypeButtons, OtpLoginForm } from '../components/OtpLogin'

const getLoginMethod = (query: URLSearchParams, methods: string[]) => {
  for (let methodKey in OTPLoginMethods) {
    if (query.has(methodKey) && methods?.includes(OTPLoginMethods[methodKey]))
      return OTPLoginMethods[methodKey]
  }

  return ''
}

const extractQueryCredentials = (query: URLSearchParams, type: string = '') => {
  const key = Object.keys(OTPLoginMethods)?.find(
    (key) => OTPLoginMethods[key] === type
  )
  return query.get(key)
}

const phoneRegex = /^\+[0-9]{10,}$/

interface Props {
  config?: PortalConfig
  t: TFunction
}

const OtpLogin: React.FC<Props> = ({ t, config }) => {
  const methods = Object.keys(config?.otpLoginMethods)

  const query = useQuery()
  const queryType = getLoginMethod(query, methods)
  const queryId = extractQueryCredentials(query, queryType)

  const [id, setId] = useState(queryId)
  const [type, setType] = useState(queryType || '')

  const formikRef = React.useRef(null)
  const buttonRef = React.useRef(null)

  const history = useHistory()
  const { addToast } = useMessages()

  const { auth, refreshCache } = authService

  const [, dispatch] = useStore()
  React.useEffect(() => {
    dispatch(unsetLayout(true))
  }, [dispatch])

  React.useEffect(() => {
    // Redirect coming from 401 refresh
    if (window.history.state?.expired) {
      addToast(
        MESSAGE_STATUS.ERROR,
        t(['errors.sessionExpired', 'errors.genericErrorMessage', ''])
      )
    }
  }, [addToast, t])

  const onBackClick = (formik) => {
    setType('')
    setId('')
    formik.setFieldValue('id', '')
  }

  const createValidationSchema = () => {
    switch (type) {
      case 'phone':
        return Yup.string()
          .matches(phoneRegex)
          .required(t(['forms.requiredField', '']))
      case 'email':
        return Yup.string()
          .email(' ')
          .required(t(['forms.requiredField', '']))
      default:
        return Yup.string().required(t(['forms.requiredField', '']))
    }
  }

  const validationSchema = Yup.object({
    id: createValidationSchema(),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    authService
      .otpLogin(values.id, type)
      .then((res) => {
        setSubmitting(false)
        history.push({
          pathname: ROUTES.OTP,
          state: { otpChannels: res.data?.otpChannels },
        })
      })
      .catch((e) => {
        setSubmitting(false)
        const errorMessage = getErrorMessage(e, 'otpLogin')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  if (auth && isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  } else {
    refreshCache([LOCAL_STORAGE_KEYS.CONFIG, LOCAL_STORAGE_KEYS.REDIRECT_URL])
  }

  const renderCaption = (formik) => {
    if (!type) {
      return t(['otpLogin.caption', ''])
    } else {
      return formik.isSubmitting
        ? t(['otpLogin.loadingCaption', ''])
        : t([`otpLogin.${type}Caption`, ''])
    }
  }

  useEffect(() => {
    if (id && type) {
      formikRef?.current
        ?.setFieldValue('id', id)
        ?.then(() => buttonRef.current.click())
    }
  }, [id, type])

  return (
    <StyledFlexColumn
      align="center"
      justify="center"
      margin="60px auto"
      padding="0 20px"
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          id: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <Heading
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: t('otpLogin.heading', ''),
              }}
            />
            <Caption
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: renderCaption(formik),
              }}
            />

            {type ? (
              <OtpLoginForm
                formik={formik}
                value={id}
                type={type}
                buttonRef={buttonRef}
              />
            ) : (
              <LoginTypeButtons setType={setType} config={config} />
            )}

            {formik.isSubmitting && (
              <StyledFlexColumn margin="100px">
                <RotatingLines
                  strokeColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--pages-mainContainer-primaryTextColor')}
                  strokeWidth="3"
                  animationDuration="0.75"
                  width="64"
                  visible={true}
                />
              </StyledFlexColumn>
            )}
            {type && !formik.isSubmitting && (
              <LinkButton
                className="buttons-linkColor"
                margin="40px 0 0 0"
                onClick={() => onBackClick(formik)}
              >
                {t('otpLogin.backButton', '')}
              </LinkButton>
            )}
          </>
        )}
      </Formik>
    </StyledFlexColumn>
  )
}

export default withTranslation()(OtpLogin)
