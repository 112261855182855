import React from 'react'
import { ROUTES } from '../constants'

import L1 from './L1'
import L2 from './L2'
import L3 from './L3'
import L100 from './L100'

const Layouts = {
  1: L1,
  2: L2,
  3: L3,
  100: L100,
}

let layoutIndex = 0

export const generateLayout = (layout: any, props = {}) => {
  // TODO keep index or use layout.id
  if (typeof Layouts[layout.layoutId] !== 'undefined') {
    return React.createElement(Layouts[layout.layoutId], {
      key: layoutIndex++,
      ...layout,
      ...props,
    })
  }
  // component doesn't exist yet
  // return React.createElement(
  //   () => <div>The component {layout.component} has not been created yet.</div>,
  //   { key: layout._uid }
  // );
}

export const Buttons = {
  showHistory: ROUTES.HISTORY,
  showCards: ROUTES.DASHBOARD,
  showOption: ROUTES.DASHBOARD,
  link: ROUTES.DASHBOARD,
}
