import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import Loader from './Loader'
import { UIService } from '../services'
import { PortalConfig } from '../helpers/useConfig'
import { LANGUAGE_LABELS } from '../constants'

const Select = styled.select`
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  color: var(--pages-header-textColor);
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  option {
    color: var(--pages-mainContainer-secondaryTextColor);
  }
`

const SelectWrapper = styled.div`
  &.one-option {
    select {
      text-indent: 1px;
      text-overflow: '';
    }
  }
`
type LanguageSwitcherProps = {
  config: PortalConfig
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ config }) => {
  const { i18n } = useTranslation()
  const [changingLanguage, setChangingLanguage] = useState(false)

  const { user } = UIService
  const [isLoggedIn, setIsLoggedIn] = React.useState(!!user?.username)

  const updateUser = () => {
    const { user } = UIService
    setIsLoggedIn(!!user?.username)
  }

  useEffect(() => {
    // Should we reflect this in DEFAULT_LANGUAGE contant
    // We can potentially get rid of this side effect if we match the contant with config
    onChange(config?.defaultLanguage)
    // We can safely disable eslint here
    // since onChange does not need to be updated with the state of this component
    // remove when needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  React.useEffect(() => {
    // Listen To login events
    window.addEventListener('updateUser', updateUser)
    return () => {
      // Remove listener when component unmounts
      window.removeEventListener('updateUser', updateUser)
    }
  }, [])

  // TODO get available languages from locale
  const options =
    config?.languages?.map((o) => ({
      label: LANGUAGE_LABELS[o] || o,
      value: o,
    })) || []

  const extraClasses = cx('select', {
    'one-option': options.length === 1,
  })

  const onChange = (lang: string) => {
    const new_lang = lang?.replace('-', '_') || ''
    if (!lang) {
      return
    }
    // Disabling Local storage temporarily
    // localStorage.setItem(LOCAL_STORAGE_KEYS.LANG, new_lang)
    setChangingLanguage(true)
    i18n.changeLanguage(new_lang).then(() => setChangingLanguage(false))
    document.documentElement.lang = lang
  }

  // If the person is logged in
  // For now, we are hiding the language switcher
  // Rationale for that is, We dont wanna reload the all the options and config files
  if (isLoggedIn || options?.length <= 1 || !options?.length) {
    return null
  }
  return (
    <>
      {changingLanguage ? (
        <Loader />
      ) : (
        <SelectWrapper id="language-switcher" className={extraClasses}>
          <Select
            value={i18n.language}
            onChange={(e) => onChange(e.target.value)}
            title="language-switcher"
          >
            {options &&
              options.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
          </Select>
        </SelectWrapper>
      )}
    </>
  )
}

export default LanguageSwitcher
