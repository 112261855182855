import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { useMessages, MESSAGE_STATUS } from './context/messages'
import { authService } from './services'

const MfaLoginRoute = ({ component: Component, config, t, ...rest }) => {
  const { addToast } = useMessages()
  const { auth } = authService

  React.useEffect(() => {
    if (!auth && auth !== null) {
      addToast(MESSAGE_STATUS.ERROR, t(['errors.notAuthenticated']))
    }
  }, [addToast, t, auth])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }

        return <Component config={config} {...props} />
      }}
    />
  )
}

export default withTranslation()(MfaLoginRoute)
