import { useEffect } from 'react'
import { UIService } from '../services'

const useLoadActions = (actions: any) => {
  useEffect(() => {
    actions?.forEach((action) => {
      UIService.postUserOptions(action)
    })
  }, [actions])
}

export default useLoadActions
