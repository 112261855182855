import { ColorsT } from './types'

export const breakpoints = {
  xs: 0,
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
}

export const gutters = {
  container: {
    sm: 20,
    md: 30,
    lg: 70,
  },
  column: {
    sm: 20,
    md: 20,
    lg: 20,
  },
}

export const colors: ColorsT = {
  base1: '#FFFFFF',
  primary1: '#30A74B',
  primary2: '#000000',
  error: '#EB5757',
  success: '#30A74B',
  secondary1: '#969999',
  secondary2: '#F7F7F7',
  accent: '#059BC2',
}

export const messageColors = {
  success: colors.success,
  error: colors.error,
}

export const fonts = {
  fontFamily: 'Roboto, sans-serif',
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap',
}
