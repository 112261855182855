import { FormikErrors, FormikProps } from 'formik'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import './input.css'

const CustomInput = styled.div`
  position: relative;
  padding: 10px 0;
  margin: 10px 0;
  width: 100%;

  input:focus,
  input:not(:placeholder-shown) {
    color: var(--pages-mainContainer-primaryTextColor);
  }
`

const StyledInput = styled.input<{
  error: string | FormikErrors<any> | string[] | FormikErrors<any>[]
}>`
  background: transparent;
  border: none;
  border-radius: 0;
  transition: padding 0.4s;
  padding: 23px 3px 7px;
  color: ${({ error, theme }) =>
    error ? theme.colors.error : 'var(--pages-mainContainer-primaryTextColor)'};
  border-bottom: solid 1px
    ${({ error, theme }) =>
      error
        ? theme.colors.error
        : 'var(--pages-mainContainer-primaryTextColor)'};
  width: inherit;
`

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.error};
  margin: 8px 0;
  text-align: left;
`
interface InputFieldProps {
  id: string
  label: string
  placeholder?: string
  type: string
  formik: FormikProps<any>
  className?: string
  autocomplete?: string
  style?: CSSProperties
}

const InputField: React.FC<InputFieldProps> = (props) => {
  const { id, type, placeholder = '', label, formik, className = '' } = props
  const error = formik.touched[id] && formik.errors[id]
  return (
    <CustomInput className={'custom-input-field ' + className}>
      <StyledInput
        id={id}
        type={type}
        placeholder={placeholder ? placeholder : label}
        error={error}
        {...props}
        {...formik.getFieldProps(id)}
      />
      <label htmlFor={id}>{label}</label>
      {error ? <ErrorMessage>{formik.errors[id]}</ErrorMessage> : null}
    </CustomInput>
  )
}

export default InputField
