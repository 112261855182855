import React from 'react'
import { useStore } from '../context'
import { next } from '../context/actionReducer'
import actionCodes from '../context/actionCodes'
import { ColorsT } from '../theme/types'
import { getToken, setToken, themeForText } from '../helpers'
import { LOCAL_STORAGE_KEYS } from '../constants'
import { useMessages } from '../context/messages'
import Modal, {
  ModalLoadButton,
  ModalSubtitle,
  ModalTitle,
  ClearData,
  LastModifiedLabel,
} from '../components/Modal'
import merge from 'ts-deepmerge'
import { UIService } from '../services'
import Loader from '../components/Loader'
import {
  labelForName,
  buttonForName,
  Button,
  Label,
  handleButtonActionTypeClick,
} from '../helpers'
import {
  StyledCardButton,
  StyledCard,
  StyledCardTitle,
  StyledCardDesc,
  StyledCardBoxWrapper,
  StyledCardBoxContainer,
  StyledCardBox,
} from './styles'
import { StyledColumn, StyledGrid } from '../components/commonStyles'
import CardBox from '../components/Cards/CardBox'
import { TFunction, withTranslation } from 'react-i18next'

interface L1Props {
  labels: Label[]
  buttons: Button[]
  colors: ColorsT
  height: number
  setHeight: React.Dispatch<React.SetStateAction<number>>
  theme?: any
  t: TFunction
}

const L1: React.FC<L1Props> = (props) => {
  const [, dispatch] = useStore()
  const { labels, buttons, theme, t } = props
  const { addToast } = useMessages()

  const [showModal, setShowModal] = React.useState(false)
  const [cacheIsLoading, setCacheIsLoading] = React.useState(false)
  const [headIsLoading1, setHeadIsLoading1] = React.useState(false)
  const [headIsLoading2, setHeadIsLoading2] = React.useState(false)
  const [buttonActionClicked, setButtonActionClicked] = React.useState(0)
  const [lastModified, setLastModified] = React.useState('')

  const title = labelForName('title', labels)
  const description = labelForName('description', labels)
  const bottomBoxesTitle = labelForName('bottomBoxesTitle', labels)
  const box1Title = labelForName('box1Title', labels)
  const box1Value = labelForName('box1Value', labels)
  const box1Description = labelForName('box1Description', labels)
  const box2Title = labelForName('box2Title', labels)
  const box2Value = labelForName('box2Value', labels)
  const box2Description = labelForName('box2Description', labels)
  const box3Title = labelForName('box3Title', labels)
  const box3Value = labelForName('box3Value', labels)
  const box3Description = labelForName('box3Description', labels)

  const button1 = buttonForName('button1', buttons)
  const button2 = buttonForName('button2', buttons)

  const initStep1 = button1?.action?.option?.steps
    ? button1?.action?.option?.steps[0]?.id
    : 0
  const step1 = initStep1 || 0
  const { stepItems1 } = button1?.action?.option?.steps
    ? button1.action?.option?.steps.find((st) => st.id === step1).items
    : []

  const initStep2 = button2?.action?.option?.steps
    ? button2?.action?.option?.steps[0]?.id
    : 0
  const step2 = initStep2 || 0
  const { stepItems2 } = button2?.action?.option?.steps
    ? button2.action?.option?.steps.find((st) => st.id === step2).items
    : []

  const loadCache = () => {
    setCacheIsLoading(true)
    const customerActions = actionCodes.getCustomerActions()
    UIService.getCustomerCache(
      customerActions.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    )
      .then((res) => {
        if (res.data) {
          let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
          if (!crnCache) {
            crnCache = {}
          }
          // merge on correct caseId > flowId > flowInstanceId
          let crnFormCache =
            crnCache[customerActions.caseId]?.[customerActions.flowId]?.[
              customerActions.flowInstanceId
            ]
          if (!crnFormCache) {
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: res.data,
                },
              },
            }
          } else {
            const toMerge = merge(crnFormCache, res.data)
            crnFormCache = {
              [customerActions.caseId]: {
                [customerActions.flowId]: {
                  [customerActions.flowInstanceId]: toMerge,
                },
              },
            }
          }
          setToken(LOCAL_STORAGE_KEYS.CACHE, merge(crnCache, crnFormCache))
        }
      })
      .catch((e) => {
        if (!getToken(LOCAL_STORAGE_KEYS.CACHE)) {
          addToast('error', t('cacheModal.cacheError', ''))
        }
      })
      .finally(() => {
        setCacheIsLoading(false)
        setShowModal(false)
        dispatch(next(button1?.action))
      })
  }

  const clearCache = () => {
    let crnCache = getToken(LOCAL_STORAGE_KEYS.CACHE)
    if (crnCache) {
      delete crnCache[button1?.action.caseId]
      setToken(LOCAL_STORAGE_KEYS.CACHE, crnCache)
    }

    if (buttonActionClicked === 1) {
      if (stepItems1 && stepItems1.length > 0) {
        const codesToClear = stepItems1.map((item) => item.code)
        actionCodes.clearCodes(button1.action.caseId, codesToClear)
      }
    } else if (buttonActionClicked === 2) {
      if (stepItems2 && stepItems2.length > 0) {
        const codesToClear = stepItems2.map((item) => item.code)
        actionCodes.clearCodes(button2.action.caseId, codesToClear)
      }
    }

    const customerActions = actionCodes.getCustomerActions()
    UIService.deleteCustomerCacheForCase(
      button1?.action?.caseId,
      customerActions.flowId,
      customerActions.flowInstanceId
    ).finally(() => {
      setShowModal(false)
      dispatch(next(button1?.action))
    })
  }

  return (
    <StyledCardBox
      style={{ display: 'flex', flexBasis: '100%', marginLeft: '0px' }}
    >
      <Modal show={showModal}>
        <ModalTitle>{t('cacheModal.title', '')}</ModalTitle>
        <ModalSubtitle>{t('cacheModal.subtitle', '')}</ModalSubtitle>
        <div className="row">
          <LastModifiedLabel>
            Information saved {lastModified}
          </LastModifiedLabel>
          <ModalLoadButton onClick={loadCache}>
            {cacheIsLoading ? <Loader /> : t('cacheModal.loadButton', '')}
          </ModalLoadButton>
          <ClearData onClick={clearCache}>
            {t('cacheModal.clearButton', '')}
          </ClearData>
        </div>
      </Modal>

      <StyledCard
        className="L1_StyleCard"
        style={{ display: 'flex', flexFlow: 'column', flexBasis: '100%' }}
        {...props.colors}
        customTheme={theme}
      >
        {title && (
          <StyledCardTitle
            dangerouslySetInnerHTML={{ __html: title }}
            customTheme={themeForText(title, labels)}
          />
        )}
        {description && (
          <StyledCardDesc
            dangerouslySetInnerHTML={{ __html: description }}
            customTheme={themeForText(description, labels)}
          />
        )}
        <StyledCardBoxContainer
          customTheme={themeForText(bottomBoxesTitle, labels)}
        >
          <StyledGrid valign="bottom">
            {bottomBoxesTitle && (
              <div className="boxes-title">{bottomBoxesTitle}</div>
            )}
            <StyledCardBoxWrapper>
              {(box1Title || box1Value || box1Description) && (
                <CardBox
                  header={box1Title}
                  value={box1Value}
                  desc={box1Description}
                  height={props.height}
                  setHeight={props.setHeight}
                  headerTheme={themeForText(box1Title, labels)}
                  valueTheme={themeForText(box1Value, labels)}
                  descTheme={themeForText(box1Description, labels)}
                />
              )}
              {(box2Title || box2Value || box2Description) && (
                <CardBox
                  header={box2Title}
                  value={box2Value}
                  desc={box2Description}
                  height={props.height}
                  setHeight={props.setHeight}
                  headerTheme={themeForText(box2Title, labels)}
                  valueTheme={themeForText(box2Value, labels)}
                  descTheme={themeForText(box2Description, labels)}
                />
              )}
              {(box3Title || box3Value || box3Description) && (
                <CardBox
                  header={box3Title}
                  value={box3Value}
                  desc={box3Description}
                  height={props.height}
                  setHeight={props.setHeight}
                  headerTheme={themeForText(box3Title, labels)}
                  valueTheme={themeForText(box3Value, labels)}
                  descTheme={themeForText(box3Description, labels)}
                />
              )}
            </StyledCardBoxWrapper>
          </StyledGrid>

          <StyledColumn halign="flex-end" gap={12} padding="0">
            {button1 && (
              <StyledCardButton
                onClick={() =>
                  handleButtonActionTypeClick(
                    button1,
                    dispatch,
                    setHeadIsLoading1,
                    setLastModified,
                    setShowModal,
                    addToast,
                    t,
                    1,
                    setButtonActionClicked,
                    button2
                  )
                }
                customTheme={button1.theme}
              >
                {headIsLoading1 ? <Loader /> : button1.text}
              </StyledCardButton>
            )}

            {button2 && (
              <StyledCardButton
                onClick={() =>
                  handleButtonActionTypeClick(
                    button1,
                    dispatch,
                    setHeadIsLoading2,
                    setLastModified,
                    setShowModal,
                    addToast,
                    t,
                    2,
                    setButtonActionClicked
                  )
                }
                customTheme={button2.theme}
              >
                {headIsLoading2 ? <Loader /> : button2.text}
              </StyledCardButton>
            )}
          </StyledColumn>
        </StyledCardBoxContainer>
      </StyledCard>
    </StyledCardBox>
  )
}

export default withTranslation()(L1)
