import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { authService } from '../services'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import {
  StyledFormButton,
  Heading,
  Caption,
  InputFieldContainer,
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledForm,
} from '../components/commonStyles'
import { getErrorMessage } from '../helpers'
import { AxiosError } from 'axios'

interface ForgotPasswordProps {
  t: TFunction
}

interface Values {
  email: string
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const { addToast } = useMessages()
  const { t } = props

  const validationSchema = Yup.object({
    email: Yup.string().required(t(['forms.requiredField'])),
  })

  const handleSubmit = (
    values: Values,
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    authService
      .forgotPassword(values.email)
      .then(() => addToast(MESSAGE_STATUS.SUCCESS, t(['forgotPassword.200'])))
      .catch((e: AxiosError) => {
        setSubmitting(false)
        const errorMessage = getErrorMessage(e, 'forgotPassword')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('forgotPassword.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('forgotPassword.caption', ''),
            }}
          />
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit}>
                <InputFieldContainer>
                  <InputField
                    id="email"
                    label={t('forgotPassword.emailLabel', '')}
                    type="email"
                    formik={formik}
                  />
                </InputFieldContainer>
                <div>
                  <StyledFormButton
                    type="submit"
                    id="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {formik.isSubmitting ? (
                      <Loader />
                    ) : (
                      <span>{t('forgotPassword.confirmButton', '')}</span>
                    )}
                  </StyledFormButton>
                </div>
              </StyledForm>
            )}
          </Formik>
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(ForgotPassword)
