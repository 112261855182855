import styled from 'styled-components'
import { ColorsT } from '../theme/types'
//import { useStore } from '../context'
//import { next } from '../context/actionReducer'
import { StyledCard, StyledCardTitle, StyledCardDesc } from './styles'
import {
  Label,
  Button,
  labelForName,
  buttonForName,
  themeForText,
} from '../helpers'

const StyledSideCard = styled(StyledCard)`
  margin: 0 0 20px;
`

const StyledSideCardDesc = styled(StyledCardDesc)`
  margin: 0;
`

const StyledLink = styled.a<{
  customTheme?: {
    textColor?: string
    backgroundColor?: string
    borderColor?: string
  }
}>`
  display: block;
  color: ${(props) =>
    props.customTheme
      ? props.customTheme.textColor
      : 'var(--buttons-buttonLinkColor)'};
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  &:first-of-type {
    margin-top: 28px;
  }

  &:visited {
    color: var(--buttons-buttonLinkColor);
  }

  &:hover {
    color: var(--buttons-buttonLinkHoverColor);
  }
`

interface L100Props {
  labels: Label[]
  buttons: Button[]
  colors: ColorsT
  theme?: any
}

export default function L100(props: L100Props) {
  //const [, dispatch] = useStore()
  const { buttons, labels, theme } = props
  const button1 = buttonForName('button1', buttons)
  const button2 = buttonForName('button2', buttons)
  const title = labelForName('title', labels)
  const description = labelForName('description', labels)

  return (
    <StyledSideCard
      className="L100_StyleSideCard"
      {...props.colors}
      customTheme={theme}
    >
      {title && (
        <StyledCardTitle
          dangerouslySetInnerHTML={{ __html: title }}
          customTheme={themeForText(title, labels)}
        />
      )}
      {description && (
        <StyledSideCardDesc
          dangerouslySetInnerHTML={{ __html: description }}
          customTheme={themeForText(description, labels)}
        />
      )}
      {button1 && (
        <StyledLink
          as="a"
          href={button1.action.url}
          target="_blank"
          rel="noopener noreferrer"
          customTheme={button1.theme}
        >
          {button1.text}
        </StyledLink>
      )}
      {button2 && (
        <StyledLink
          as="a"
          href={button2.action.url}
          target="_blank"
          rel="noopener noreferrer"
          customTheme={button2.theme}
        >
          {button2.text}
        </StyledLink>
      )}
    </StyledSideCard>
  )
}
