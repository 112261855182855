import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import Field from './Dataform/Field'
import actionCodes from '../context/actionCodes'
import { formatDate } from '../helpers/form'

import {
  StyledTitleContainer,
  StyledTitle,
  StyledDivider,
  StyledDesc,
  StyledFieldLabel,
} from './styles'

const Container = styled.div`
  //margin: 6px 0;
  margin-bottom: 28px;

  // ${StyledFieldLabel} {
  //   margin-top: 26px;
  // }
`

interface PtpProps {
  amountTitle: string
  code: string
  dateTitle: string
  description: string
  minDate: string
  maxDate: string
  minAmount: string
  proposedAmount: string
  proposedDate: string
  title: string
  formikRef: React.MutableRefObject<{}>
  currencySymbol: string
  amountDescription: string
  dateDescription: string
  caseId: string
  flowId: string
  flowInstanceId: string
  t: TFunction
}

const PtpForm: React.FC<PtpProps> = (props) => {
  const { t, caseId, flowId, flowInstanceId, code } = props

  const fminAmount: number = parseFloat(props.minAmount) || 0.0
  let ptpAmount: number = actionCodes.getValue(caseId, props.code, 'ptpAmount')
  if (!ptpAmount) {
    ptpAmount = parseFloat(props.proposedAmount) || 0.0
    actionCodes.addCode(caseId, props.code, 'ptpAmount', ptpAmount)
  }

  let ptpDate: Date = actionCodes.getValue(caseId, props.code, 'ptpDate')
  if (!ptpDate) {
    ptpDate = formatDate(props.proposedDate)
    actionCodes.addCode(caseId, props.code, 'ptpDate', ptpDate.toISOString())
  }

  const maxDayPlusOne = new Date(props.maxDate)
  maxDayPlusOne.setDate(maxDayPlusOne.getDate() + 1)
  const validationSchema = Yup.object({
    ptpAmount: Yup.number()
      .min(fminAmount)
      .required(t('forms.requiredField', '')),
    ptpDate: Yup.date()
      .max(maxDayPlusOne)
      .required(t('forms.requiredField', '')),
  })

  return (
    <Container className="ptp-container">
      <StyledTitleContainer>
        {props.title && (
          <StyledTitle>
            <span>{props.title}</span>
            <StyledDivider />
          </StyledTitle>
        )}
        {props.description && (
          <StyledDesc
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></StyledDesc>
        )}
      </StyledTitleContainer>
      <Formik
        initialValues={{
          ptpAmount,
          ptpDate,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={() => console.log('Submited')}
      >
        {(formik) => {
          props.formikRef.current[code] = formik
          return (
            <form onSubmit={formik.handleSubmit}>
              <Field
                id="ptpAmount"
                name="ptpAmount"
                type="6"
                label={props.amountTitle}
                formik={formik}
                code={code}
                value={formik.values['ptpAmount']}
                currencySymbol={props.currencySymbol}
                description={props.amountDescription}
                shouldCache={true}
                caseId={caseId}
                flowId={flowId}
                flowInstanceId={flowInstanceId}
              />

              <Field
                id="ptpDate"
                name="ptpDate"
                type="4"
                label={props.dateTitle}
                formik={formik}
                code={code}
                minDate={new Date(props.minDate)}
                maxDate={new Date(props.maxDate)}
                value={formik.values['ptpDate'].toISOString()}
                description={props.dateDescription}
                shouldCache={true}
                caseId={caseId}
                flowId={flowId}
                flowInstanceId={flowInstanceId}
              />
            </form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default withTranslation()(PtpForm)
