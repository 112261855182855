import React, { Suspense } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { createBrowserHistory } from 'history'
import PrivateRoute from './PrivateRoute'
import ConfigRoute from './ConfigRoute'
import ForceLogoutRoute from './ForceLogoutRoute'
import MfaLoginRoute from './MfaLoginRoute'
import './App.css'
import Header from './components/Header'
import Loader from './components/Loader'
import Footer from './components/Footer'
import { ROUTES } from './constants'
import { GlobalStyle, MainContainer } from './components/commonStyles'
import MessagesProvider from './context/messages'
import * as themeConfig from './theme'
import {
  ChangePassword,
  Dashboard,
  ForgotPassword,
  History,
  Login,
  NewPassword,
  NotFound,
  Register,
  VerifyCode,
  LinkPage,
  Otp,
  MfaChannel,
  OtpLogin,
  Callback,
} from './pages'
import ScrollButton from './components/ScrollButton'
import { ThemeT } from './theme/types'
import useConfig from './helpers/useConfig'
// import ErrorBoundary from './components/ErrorBoundary'

const history = createBrowserHistory()
const theme: ThemeT = { ...themeConfig }

const App: React.FC = () => {
  const [config, fontFamily] = useConfig()

  return (
    <ThemeProvider theme={theme}>
      <MessagesProvider>
        <GlobalStyle customFont={fontFamily} />
        <Suspense fallback={<Loader />}>
          <Router history={history}>
            <Header config={config} />
            {/* <ErrorBoundary> */}
            <MainContainer>
              <Switch>
                {config?.authMode === 'otp' ? (
                  <ConfigRoute
                    exact
                    path={ROUTES.LOGIN}
                    component={OtpLogin}
                    config={config}
                  />
                ) : (
                  <Route exact path={ROUTES.LOGIN} component={Login} />
                )}
                <MfaLoginRoute exact path={ROUTES.OTP_CODE} component={Otp} />
                <MfaLoginRoute
                  exact
                  path={ROUTES.OTP}
                  component={MfaChannel}
                  config={config}
                />
                {config?.authMode !== 'otp' && (
                  <ForceLogoutRoute
                    path={ROUTES.REGISTER_CODE}
                    component={VerifyCode}
                    config={{}}
                  />
                )}
                {config?.authMode !== 'otp' && (
                  <ForceLogoutRoute
                    path={ROUTES.REGISTER_NEW_PSSW}
                    component={NewPassword}
                    config={config}
                  />
                )}
                {config?.authMode !== 'otp' && (
                  <ForceLogoutRoute
                    path={ROUTES.RESET_PSSW}
                    component={NewPassword}
                    config={config}
                  />
                )}
                {config?.authMode !== 'otp' && (
                  <ForceLogoutRoute
                    path={ROUTES.REGISTER}
                    component={Register}
                    config={{}}
                  />
                )}
                {config?.authMode !== 'otp' && (
                  <PrivateRoute
                    path={ROUTES.CHANGE_PSSW}
                    component={ChangePassword}
                    config={config}
                  />
                )}
                {config?.authMode !== 'otp' && (
                  <Route
                    exact
                    path={ROUTES.FORGOT_PSSW}
                    component={ForgotPassword}
                  />
                )}
                <Route path={ROUTES.LINK_PAGE} component={LinkPage} />
                <PrivateRoute
                  exact
                  path={ROUTES.DASHBOARD}
                  component={Dashboard}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.CALLBACK}
                  component={Callback}
                />
                <PrivateRoute
                  exact
                  path={ROUTES.HOME}
                  component={() => <Redirect to={ROUTES.DASHBOARD} />}
                />
                <PrivateRoute exact path={ROUTES.HISTORY} component={History} />
                <Route component={NotFound} />
              </Switch>
              <ScrollButton />
            </MainContainer>
            {/* </ErrorBoundary> */}
            <Footer />
          </Router>
        </Suspense>
      </MessagesProvider>
    </ThemeProvider>
  )
}

export default App
