import { t } from 'i18next'
import React from 'react'
import { FiPhone } from 'react-icons/fi'
import { HiOutlineUser } from 'react-icons/hi'
import { TbMail } from 'react-icons/tb'
import { OTPLoginMethods } from '../../constants'
import IconButton from '../IconButton'
import { StyledFlexColumn } from '../commonStyles'
import { PortalConfig } from '../../helpers/useConfig'

interface Props {
  setType: any
  config: PortalConfig
}

const LoginTypeButtons: React.FC<Props> = ({ setType, config }) => {
  return (
    <StyledFlexColumn margin="40px 0 0 0" align="normal" justify="center">
      {config?.otpLoginMethods['email']?.visible && (
        <IconButton
          text={t('otpLogin.emailButton', '')}
          icon={<TbMail size={24} />}
          onClick={() => setType(OTPLoginMethods['e'])}
        />
      )}
      {config?.otpLoginMethods['phone']?.visible && (
        <IconButton
          text={t('otpLogin.phoneButton', '')}
          icon={<FiPhone size={24} />}
          onClick={() => setType(OTPLoginMethods['p'])}
        />
      )}
      {config?.otpLoginMethods['username']?.visible && (
        <IconButton
          text={t('otpLogin.usernameButton', '')}
          icon={<HiOutlineUser size={24} />}
          onClick={() => setType(OTPLoginMethods['u'])}
        />
      )}
    </StyledFlexColumn>
  )
}

export default LoginTypeButtons
