import axios, { AxiosError } from 'axios'
import { STATUS_CODES, SERVICES, ROUTES } from '../constants'
import { axiosAuthDelete, axiosAuthGet, axiosAuthHead, axiosAuthPost } from '.'
import i18n from '../i18n'

const axiosClient = (authService) => {
  let client = axios.create()

  client.interceptors.request.use((req) => {
    const { width, height } = window.screen
    const { clientWidth, clientHeight } = document.documentElement

    const userMetaHeader = {
      'X-UA-Metadata': `sr=${width}x${height}&wr=${clientWidth}x${clientHeight}&bl=${navigator.language}`,
    }

    // Send Current Language in All requests Headers
    // Since we are getting the language from i18n
    // which accepts underscore file format
    // we should convert to dashes for Headers
    const languageHeader = {
      'Accept-Language': i18n.language.replace('_', '-'),
    }

    return {
      ...req,
      headers: { ...req.headers, ...userMetaHeader, ...languageHeader },
    }
  })

  let maxRetries = 3

  client.interceptors.response.use(
    (res) => res,
    (err: AxiosError) => {
      const {
        response,
        config: { url, data, headers, method, params },
      } = err

      const { pathname } = new URL(url)
      const { auth, logout } = authService

      if (
        response &&
        response.status === STATUS_CODES.UNAUTHORIZED &&
        pathname === SERVICES.REFRESH_TOKEN
      ) {
        logout()

        window.history.pushState({ expired: true }, null, ROUTES.LOGIN)
        window.location.href = ROUTES.LOGIN
        return Promise.reject(err)
      }

      if (
        response &&
        response.status === STATUS_CODES.UNAUTHORIZED &&
        auth?.refreshToken &&
        maxRetries
      ) {
        maxRetries--
        return authService.refreshToken().then(() => {
          switch (method) {
            case 'get':
              return axiosAuthGet(pathname, headers, params)
            case 'post':
              return axiosAuthPost(pathname, data, headers)
            case 'delete':
              return axiosAuthDelete(pathname, headers)
            case 'head':
              return axiosAuthHead(pathname, headers, params)
          }
        })
      }

      return Promise.reject(err)
    }
  )
  return client
}

export default axiosClient
