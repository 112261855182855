import i18n from '../i18n'

/**
 * Formats the date to current language in i18n
 */
export const toLocaleDateString = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) => {
  return date.toLocaleDateString(i18n.language.replace('_', '-'), options || {})
}
