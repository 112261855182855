import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import LoadingBar from 'react-top-loading-bar'
import cx from 'classnames'
import { TFunction, withTranslation } from 'react-i18next'
import { ReactComponent as ClockIcon } from '../theme/icons/access_time.svg'
import { useStore } from '../context'
import { MESSAGE_STATUS, useMessages } from '../context/messages'
import { goBack, setLayout, unsetLayout } from '../context/actionReducer'
import { UIService } from '../services'
import { ROUTES } from '../constants'
import { Buttons, generateLayout } from '../layouts'
import Steps from '../components/Steps'
import { ReactComponent as HomeIcon } from '../theme/icons/home.svg'
import { ReactComponent as BackIcon } from '../theme/icons/forward.svg'
import LoadingOptions from '../components/LoadingOptions'
import { v4 as uuidv4 } from 'uuid'
import {
  buttonForName,
  getErrorMessage,
  getQueryObject,
  isCallbackCard,
  isEmptyObject,
  labelForName,
  sizes1,
  sizes2,
  themeForText,
} from '../helpers'
import {
  LinkButton,
  StyledColumn,
  StyledGrid,
  StyledTitleContainer,
  StyledWrapper,
  StyledHeader,
  StyledDesc,
  StyledNavIcons,
  StyledIconButton,
} from '../components/commonStyles'
import useQuery from '../helpers/useQuery'

const RightLinkButton = styled(LinkButton)`
  text-decoration: none;
  color: var(--pages-mainContainer-primaryTextColor);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0;

  span {
    display: flex;
    margin-left: 6px;

    &:first-of-type {
      margin: 0;
    }
  }
`

const DashboardStyledWrapper = styled(StyledWrapper)`
  margin: 30px auto 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    margin: 56px auto 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 90px auto 0;
  }

  &.inner {
    @media (max-width: 500px) {
      ${RightLinkButton} {
        display: none;
      }

      ${StyledTitleContainer} {
        ${StyledColumn} {
          &:first-of-type {
            flex-basis: 100%;
            max-width: 100%;
          }
          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }
`

const StyledLoadingBar = styled(LoadingBar)`
  div {
    div {
      margin-left: -1rem;
    }
  }
`

interface DashboardProps {
  pathId?: string
  t: TFunction
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const [state, dispatch] = useStore()
  const { t, pathId } = props

  const [loading, setLoading] = React.useState(false)
  const [randomId, setRandomId] = React.useState(0)
  const loadRef = React.useRef(null)
  const [refVisible, setRefVisible] = React.useState(false)
  const [height, setHeight] = React.useState(0)
  const { addToast } = useMessages()
  const { layoutStack, layout, globalLayout } = state
  const history = useHistory()
  const handleRef = (el) => {
    loadRef.current = el
    setRefVisible(!!el)
  }

  const query = useQuery()
  const params = getQueryObject(query)

  const { main, callback, cards, option, caseId, labels } =
    layout.items || layout

  const startLoading = () =>
    loadRef.current && loadRef.current.continuousStart()

  const finishLoading = () => loadRef.current && loadRef.current.complete()

  React.useEffect(() => {
    let isMounted = true
    const rndUUID = uuidv4()
    if (isMounted) setRandomId(rndUUID)

    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    if (!!pathId && !!callback) {
      if (!isCallbackCard(pathId, callback)) history.push(ROUTES.DASHBOARD)
    }
  }, [callback, history, pathId])

  React.useEffect(() => {
    let isMounted = true
    if (!refVisible) {
      return
    }

    if ((!layout || isEmptyObject(layout)) && isMounted && !loading) {
      setLoading(true)
      startLoading()
      UIService.fetchUserOptions(params)
        .then((data) => {
          dispatch(setLayout(data))
          if (!isEmptyObject(params)) history.replace({ search: '' })
        })
        .catch((e) => {
          const errorMessage = getErrorMessage(e)
          addToast(MESSAGE_STATUS.ERROR, errorMessage)
        })
        .finally(() => {
          finishLoading()
        })
    }
    return () => {
      isMounted = false
    }
  }, [dispatch, layout, refVisible, addToast, params, history, loading, t])

  const { side, top } = globalLayout

  if (!layout || isEmptyObject(layout)) {
    return (
      <>
        <StyledLoadingBar
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--elements-loader-pageColor'
          )}
          height={8}
          ref={handleRef}
        />
        <LoadingOptions loading={loading} />
      </>
    )
  }

  const pageTitle = labels
    ? labelForName('pageTitle', labels)
    : labelForName(
        'pageTitle',
        top[0].labels ?? [{ name: 'pageTitle', text: '' }]
      )
  const pageDesc = labels
    ? labelForName('pageDesc', labels)
    : labelForName(
        'pageDesc',
        top[0].labels ?? [{ name: 'pageDesc', text: '' }]
      )
  const rightButton = buttonForName('rightButton', top[0].buttons ?? null)

  const extraProps = {
    height,
    setHeight,
  }

  const extraClassName = cx({
    inner: layoutStack.length > 0,
  })

  const handleClick = () => {
    history.push({
      pathname: Buttons[rightButton.action.type],
      state: { ...rightButton.action },
    })
  }

  const handleBackButton = () => {
    dispatch(goBack())
  }

  const handleHomeButton = () => {
    const rndUUID = uuidv4()
    setRandomId(rndUUID)
    dispatch(unsetLayout())
  }

  // TODO revisit this
  let sizes = sizes1
  if (!side || (side && side.length === 0)) {
    sizes = sizes2
  }

  // TODO Revisit error messages. This should be coming
  // from the backend configuration
  const formErrorMessages = {
    checkboxRequired: t('forms.checkboxRequired', ''),
    fieldRequired: t('forms.requiredField', ''),
    minValue: t('forms.minValue', ''),
    maxValue: t('forms.maxValue', ''),
    minLength: t('forms.minLength', ''),
    maxLength: t('forms.maxLength', ''),
  }

  const backPageTitle =
    layoutStack.length > 1
      ? layoutStack[0].items
        ? labelForName('pageTitle', layoutStack[0].items.labels)
        : t('dashboard.dashboard', '')
      : ''

  return (
    <React.Fragment>
      <StyledLoadingBar
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-loader-pageColor'
        )}
        height={8}
        ref={handleRef}
      />
      <DashboardStyledWrapper align="left" className={extraClassName}>
        {/* {loading && 'Loading...'} */}
        <StyledGrid halign="center">
          <StyledColumn size={sizes.container}>
            {!!pathId ? (
              <StyledNavIcons>
                <Link to={ROUTES.HOME} onClick={handleHomeButton}>
                  <StyledIconButton>
                    <HomeIcon />
                  </StyledIconButton>
                </Link>
              </StyledNavIcons>
            ) : (
              <>
                <StyledNavIcons>
                  {!main && (
                    <Link to={ROUTES.HOME} onClick={handleHomeButton}>
                      <StyledIconButton>
                        <HomeIcon />
                      </StyledIconButton>
                    </Link>
                  )}
                  {layoutStack.length > 1 && (
                    <StyledIconButton onClick={handleBackButton}>
                      <div>
                        <BackIcon />
                        <span>{backPageTitle}</span>
                      </div>
                    </StyledIconButton>
                  )}
                </StyledNavIcons>
                <StyledTitleContainer>
                  <StyledGrid>
                    <StyledColumn size={sizes.title}>
                      <div>
                        {pageTitle && (
                          <StyledHeader
                            customTheme={
                              labels
                                ? themeForText(pageTitle, labels)
                                : themeForText(pageTitle, top[0].labels)
                            }
                            dangerouslySetInnerHTML={{ __html: pageTitle }}
                          />
                        )}
                        {pageDesc && (
                          <StyledDesc
                            customTheme={
                              labels
                                ? themeForText(pageTitle, labels)
                                : themeForText(pageTitle, top[0].labels)
                            }
                            dangerouslySetInnerHTML={{ __html: pageDesc }}
                          />
                        )}
                      </div>
                    </StyledColumn>
                    <StyledColumn size={sizes.icon} halign="flex-end">
                      {rightButton && (
                        <RightLinkButton
                          className=""
                          onClick={handleClick}
                          customTheme={rightButton.theme}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: rightButton.text,
                            }}
                          />
                          <span>
                            <ClockIcon
                              fill={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue(
                                '--pages-mainContainer-primaryTextColor'
                              )}
                            />
                          </span>
                        </RightLinkButton>
                      )}
                    </StyledColumn>
                  </StyledGrid>
                </StyledTitleContainer>
              </>
            )}

            <StyledGrid>
              <StyledColumn size={sizes.main} style={{ margin: '0 auto' }}>
                {!!pathId ? (
                  <>
                    {callback
                      ?.filter((item) => item.path === pathId)
                      ?.map((item: {}) => generateLayout(item, extraProps))}
                  </>
                ) : (
                  <>
                    {main?.map((item: {}) => generateLayout(item, extraProps))}
                  </>
                )}
                {cards &&
                  cards.map((item: {}) => generateLayout(item, extraProps))}
                {option?.steps && (
                  <Steps
                    caseId={caseId}
                    randomJourneyId={randomId}
                    steps={option.steps}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                    errorMessages={formErrorMessages}
                  />
                )}
              </StyledColumn>
              <StyledColumn size={sizes.side}>
                {side &&
                  side.map((item: {}) =>
                    generateLayout(item, { position: 'side' })
                  )}
              </StyledColumn>
            </StyledGrid>
          </StyledColumn>
        </StyledGrid>
      </DashboardStyledWrapper>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
