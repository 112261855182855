import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const ConfigRoute = ({ component: Component, config, t, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => <Component config={config} {...props} />}
    />
  )
}

export default withTranslation()(ConfigRoute)
