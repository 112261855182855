import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
//import 'typeface-roboto'
import 'normalize.css'
import './theme/style.css'
import i18n from './i18n'
import App from './App'
import { StoreProvider } from './context'
import { initialState, actionReducer } from './context/actionReducer'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <StoreProvider initialState={initialState} reducer={actionReducer}>
      <App />
    </StoreProvider>
  </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
