import { t } from 'i18next'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import InputField from '../InputField'
import Loader from '../Loader'
import {
  StyledForm,
  InputFieldContainer,
  StyledFormButton,
} from '../commonStyles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledPhoneInput = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }

  .react-tel-input {
    margin: 16px 0;

    .flag-dropdown.open {
      z-index: 0;
    }

    .selected-flag {
      background: var(--pages-mainContainer-backgroundColor);
      border: 1px solid var(--pages-mainContainer-primaryTextColor);
      border-right: 0;
      border-left: 0;
      border-top: 0;
      border-radius: 0;
    }

    .special-label {
      background: var(--pages-mainContainer-backgroundColor);
      left: 0;
      padding-left: 12px;
      font-size: 12px;
      font-weight: 500;
    }

    .form-control {
      background: var(--pages-mainContainer-backgroundColor);
      border: 1px solid var(--pages-mainContainer-primaryTextColor);
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;

      &:focus {
        border-color: var(--pages-mainContainer-primaryTextColor);
        box-shadow: 0 0 0 1px transparent;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
        width: 100%;
      }
    }
  }
`

interface Props {
  formik: any
  type: any
  buttonRef: any
  value: string
}

const OtpLoginForm: React.FC<Props> = ({ formik, type, buttonRef, value }) => {
  const { i18n } = useTranslation()

  const currentLanguage = i18n.language
  const countryCode =
    currentLanguage?.split('_')?.[1]?.toLocaleLowerCase() || 'gb'

  return (
    <StyledForm
      textAlign="center"
      onSubmit={formik.handleSubmit}
      style={{
        display: formik.isSubmitting ? 'none' : 'block',
      }}
    >
      <InputFieldContainer>
        {type === 'phone' ? (
          <StyledPhoneInput>
            <PhoneInput
              specialLabel={t([`otpLogin.${type}Label`, ''])}
              country={countryCode}
              value={value}
              enableSearch
              onChange={(phone) => formik.setFieldValue('id', '+' + phone)}
            />
          </StyledPhoneInput>
        ) : (
          <InputField
            id="id"
            label={t([`otpLogin.${type}Label`, ''])}
            type="text"
            formik={formik}
            className="otp-email-username"
            style={{ minWidth: '300px' }}
          />
        )}
      </InputFieldContainer>
      <StyledFormButton
        ref={buttonRef}
        type="submit"
        id="submit"
        disabled={!(formik.isValid && formik.dirty)}
      >
        {formik.isSubmitting ? (
          <Loader />
        ) : (
          <span>{t('otpLogin.otpLoginButton', '')}</span>
        )}
      </StyledFormButton>
    </StyledForm>
  )
}

export default OtpLoginForm
