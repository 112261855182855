import React from 'react'
import { ROUTES } from '../constants'
import { StyledLink, StyledFlexColumn } from '../components/commonStyles'
import { TFunction, withTranslation } from 'react-i18next'
import styled from 'styled-components'

interface NotFoundProps {
  t: TFunction
}

const StyledNotFoundDiv = styled.div`
  width: 50%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: 100%;
  }
`

const NotFound: React.FC<NotFoundProps> = (props) => {
  const { t } = props

  return (
    <StyledFlexColumn
      align="center"
      justify="center"
      margin="60px auto"
      padding="0 20px"
      textAlign="center"
    >
      <StyledNotFoundDiv id="not-found">
        <h1
          dangerouslySetInnerHTML={{
            __html: t('errors.heading', ''),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: t('errors.description', ''),
          }}
        />
        <StyledLink to={ROUTES.HOME}>{t('errors.linkToHome', '')}</StyledLink>
      </StyledNotFoundDiv>
    </StyledFlexColumn>
  )
}

export default withTranslation()(NotFound)
