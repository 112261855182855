import React from 'react'

export const Store = React.createContext<any>(null)
export const useStore = () => React.useContext(Store)
export const StoreProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState)

  return (
    <Store.Provider value={[globalState, dispatch]}>{children}</Store.Provider>
  )
}
