import styled from 'styled-components'
import cx from 'classnames'
import { FormikErrors, FormikProps, useField, useFormikContext } from 'formik'
import CurrencyInput from 'react-currency-input-field'
import { clearValue } from '../../helpers/form'
import { StyledBaseField } from '../styles'
import { FocusEventHandler } from 'react'

const StyledCurrencyInput = styled(CurrencyInput)`
  ${StyledBaseField}

  &.currency {
    padding-left: 20px;
  }
`
const StyledContainer = styled.div`
  position: relative;
  width: fit-content;
  display: inline-block;

  @media (max-width: 500px) {
    width: 100%;
  }
`
const StyledCurrency = styled.i`
  position: absolute;
  display: block;
  top: 19px;
  pointer-events: none;
  width: 22px;
  text-align: center;
  font-style: normal;
  font-size: 14px;
  color: var(--pages-mainContainer-primaryTextColor);
`

interface CurrencyFieldProps {
  error: string | FormikErrors<any> | string[] | FormikErrors<any>[]
  minValue?: number
  id?: string
  hidden?: boolean
  calculated?: boolean
  type?: string
  value?: string | number | readonly string[]
  readOnly?: boolean
  round?: boolean
  label?: string
  description?: string
  options?: any
  formik?: FormikProps<{}>
  name: string
  code?: string
  minDate?: string | Date
  maxDate?: string | Date
  currencySymbol?: string
  onBlur?: FocusEventHandler
  updateCodes?: (name: string, value: string | boolean) => void
}

const CurrencyField: React.FC<CurrencyFieldProps> = (props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  let options = {}

  if (!props.round) {
    options = {
      ...options,
      allowDecimals: true,
      decimalsLimit: 2,
      decimalScale: 2,
    }
  } else {
    options = {
      ...options,
      allowDecimals: false,
    }
  }

  if (props.minValue > 0) {
    options = { ...options, allowNegativeValue: false }
  }

  const value = clearValue(props.value)

  const extraClassName = cx({
    currency: !!props.currencySymbol,
  })

  return (
    <StyledContainer>
      <StyledCurrency>{props.currencySymbol}</StyledCurrency>
      <StyledCurrencyInput
        className={extraClassName}
        id={props.id}
        name={props.name}
        value={value}
        onBlur={props.onBlur}
        onValueChange={(val) => {
          setFieldValue(field.name, val)
        }}
        error={props.error}
        disabled={props.readOnly}
        hidden={props.hidden}
        groupSeparator={','}
        decimalSeparator={'.'}
        {...options}
      />
    </StyledContainer>
  )
}

export default CurrencyField
