import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { isLoggedIn, setToken } from './helpers'
import { useMessages, MESSAGE_STATUS } from './context/messages'
import { authService } from './services'
import { LOCAL_STORAGE_KEYS, ROUTES } from './constants'

const PrivateRoute = ({ component: Component, config, t, ...rest }) => {
  const { addToast } = useMessages()
  const { auth } = authService

  React.useEffect(() => {
    const userStatus = isLoggedIn(auth)
    if (!userStatus && userStatus !== null) {
      addToast(MESSAGE_STATUS.ERROR, t(['errors.notAuthenticated']))
    }
  }, [addToast, t, auth])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn(auth)) {
          setToken(
            LOCAL_STORAGE_KEYS.REDIRECT_URL,
            window.location.pathname + window.location.search
          )

          return (
            <Redirect
              to={{
                pathname: ROUTES.LOGIN,
                state: { from: props.location },
              }}
            />
          )
        }

        return <Component config={config} {...props} />
      }}
    />
  )
}

export default withTranslation()(PrivateRoute)
