import React from 'react'
import { useHistory } from 'react-router-dom'
import { TFunction, withTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { authService } from '../services'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { ROUTES } from '../constants'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import {
  StyledFormButton,
  Heading,
  Caption,
  InputFieldContainer,
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledForm,
} from '../components/commonStyles'
import { getErrorMessage } from '../helpers'
import { AxiosError } from 'axios'

interface NewPasswordProps {
  location: {
    search: string
  }
  config: {
    passwordRegexRule: RegExp
  }
  t: TFunction
}

interface Values {
  password: string
  password2: string
}

const NewPassword: React.FC<NewPasswordProps> = (props) => {
  const history = useHistory()
  const { t } = props
  const { addToast } = useMessages()

  const query = new URLSearchParams(props.location.search)
  const token = query.get('token')

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t(['forms.requiredField', '']))
      .matches(
        props.config.passwordRegexRule,
        t('newPassword.passwordRules', '')
      ),
    password2: Yup.string().required(t(['forms.requiredField'])),
  })

  const handleSubmit = (
    values: Values,
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    if (values.password === values.password2) {
      authService
        .newPassword(values.password, token)
        .then(() => {
          setSubmitting(false)
          addToast(MESSAGE_STATUS.SUCCESS, t(['newPassword.200', '']))
          history.push(ROUTES.LOGIN)
        })
        .catch((e: AxiosError) => {
          setSubmitting(false)
          const errorMessage = getErrorMessage(e, 'newPassword')
          addToast(MESSAGE_STATUS.ERROR, errorMessage)
        })
    } else {
      setSubmitting(false)
      addToast('error', t('newPassword.passwordsNotMatch', ''))
    }
  }

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('newPassword.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('newPassword.caption', ''),
            }}
          />
          <Formik
            initialValues={{ password: '', password2: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <StyledForm onSubmit={formik.handleSubmit}>
                <InputFieldContainer>
                  <InputField
                    id="password"
                    label={t('newPassword.newPasswordLabel', '')}
                    type="password"
                    formik={formik}
                  />
                  <InputField
                    id="password2"
                    label={t('newPassword.retypePasswordLabel', '')}
                    type="password"
                    formik={formik}
                  />
                </InputFieldContainer>
                <div>
                  <StyledFormButton
                    type="submit"
                    id="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                  >
                    {formik.isSubmitting ? (
                      <Loader />
                    ) : (
                      <span>{t('newPassword.newPasswordButton', '')}</span>
                    )}
                  </StyledFormButton>
                </div>
              </StyledForm>
            )}
          </Formik>
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(NewPassword)
