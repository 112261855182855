import React from 'react'
import { ListFieldProps } from '.'
import {
  StyledFieldRadioGroup,
  StyledRadioInputWrapper,
  StyledRadioInput,
} from '../../styles'

const RadioGroup: React.FC<ListFieldProps> = ({
  options,
  formik,
  formattedName,
  handleCustomBlur,
  handleCustomChange,
  readOnly,
  error,
  style,
  maxValues,
  ...rest
}) => {
  return (
    <StyledFieldRadioGroup>
      {options?.map((option: any) => (
        <StyledRadioInputWrapper
          key={option.key}
          checked={formik.values[formattedName]?.includes(option.key)}
          error={error}
        >
          <StyledRadioInput
            {...rest}
            type="checkbox"
            key={option.key}
            name={formattedName}
            onBlur={(e) => handleCustomBlur(e, true)}
            onChange={(e) => handleCustomChange(e, true)}
            disabled={readOnly}
            value={option.key}
            error={error}
            checked={formik.values[formattedName]?.includes(option.key)}
            isChecked={formik.values[formattedName]?.includes(option.key)}
          />
          {option.value}
        </StyledRadioInputWrapper>
      ))}
    </StyledFieldRadioGroup>
  )
}

export default RadioGroup
