import React from 'react'
import { LIST_TYPES } from '../../../constants'
import {
  StyledSelectFieldContainer,
  StyledFieldLabel,
  StyledFieldError,
  StyledFieldDesc,
} from '../../styles'
import Dropdown from './Dropdown'
import { ListFieldProps } from '.'
import RadioGroup from './RadioGroup'

const ListField: React.FC<ListFieldProps> = ({
  options,
  value,
  readOnly,
  description,
  formattedName,
  label,
  error,
  style,
  extraClassName,
  handleCustomBlur,
  handleCustomChange,
  formik,
  maxValues,
  ...rest
}) => {
  // TODO: add multiselect dropdown support
  const selectValue = options.length === 1 ? options[0]?.key : value

  return (
    <StyledSelectFieldContainer className={extraClassName}>
      <StyledFieldLabel htmlFor={formattedName}>{label}</StyledFieldLabel>
      {style === LIST_TYPES.DROPDOWN && maxValues === 1 ? (
        <Dropdown
          options={options}
          readOnly={readOnly}
          formattedName={formattedName}
          label={label}
          error={error}
          extraClassName={extraClassName}
          handleCustomBlur={handleCustomBlur}
          handleCustomChange={handleCustomChange}
          formik={formik}
          value={Array.isArray(selectValue) ? selectValue[0] : selectValue}
          maxValues={maxValues}
          {...rest}
        />
      ) : (
        <RadioGroup
          options={options}
          readOnly={readOnly}
          formattedName={formattedName}
          label={label}
          error={error}
          extraClassName={extraClassName}
          handleCustomBlur={handleCustomBlur}
          handleCustomChange={handleCustomChange}
          formik={formik}
          value={selectValue}
          maxValues={maxValues}
          {...rest}
        />
      )}
      {error && <StyledFieldError>{error}</StyledFieldError>}
      {description && (
        <StyledFieldDesc
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </StyledSelectFieldContainer>
  )
}

export default ListField
