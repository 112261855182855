import * as React from 'react'
import { ReactComponent as Arrow } from '../theme/icons/forward.svg'
import { ScrollTopButton } from './commonStyles'

const ScrollButton: React.FC = () => {
  const [visible, setVisible] = React.useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 500) {
      setVisible(true)
    } else if (scrolled <= 500) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisible)

    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  })

  return (
    <ScrollTopButton style={{ display: visible ? 'flex' : 'none' }}>
      <Arrow onClick={scrollToTop} />
    </ScrollTopButton>
  )
}
export default ScrollButton
