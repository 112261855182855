import actionCodes from './actionCodes'

export const ACTIONS = {
  NEXT: 'next',
  GO_BACK: 'go-back',
  SET_LAYOUT: 'set-layout',
  UNSET_LAYOUT: 'unset-layout',
  SET_THANK_YOU: 'set-thank-you',
  SET_STEP_LAYOUT: 'set-step-layout',
}

export const initialState = {
  layoutStack: [],
  layout: {},
  globalLayout: {},
}

export const next = (layout = {}) => ({
  type: ACTIONS.NEXT,
  layout,
})

export const goBack = (layout = {}) => ({
  type: ACTIONS.GO_BACK,
  layout,
})

export const setLayout = (layout = {}) => ({
  type: ACTIONS.SET_LAYOUT,
  layout,
})

export const unsetLayout = (clearAll = false) => ({
  type: ACTIONS.UNSET_LAYOUT,
  clearAll,
})

export const setThankYou = (step = {}) => ({
  type: ACTIONS.SET_THANK_YOU,
  step,
})

export const setStepLayout = (stepLayout = {}) => ({
  type: ACTIONS.SET_STEP_LAYOUT,
  stepLayout,
})

export const actionReducer = (
  state = initialState,
  action: {
    type: string
    layout: { side: any; top: any }
    clearAll: any
    step: any
    stepLayout: any
  }
) => {
  switch (action.type) {
    case ACTIONS.SET_LAYOUT:
      return {
        ...state,
        layout: { ...action.layout },
        globalLayout: {
          side: [...action.layout.side],
          top: { ...action.layout.top },
        },
      }
    case ACTIONS.UNSET_LAYOUT:
      actionCodes.clearActiveCase()
      actionCodes.resetAllFields()
      actionCodes.clearResetFields()

      if (action.clearAll) {
        actionCodes.clearActionCodes()
      }

      return action.clearAll
        ? initialState
        : {
            ...state,
            layout: {},
            layoutStack: [],
          }
    case ACTIONS.NEXT:
      state.layoutStack.unshift(state.layout)
      return {
        ...state,
        layoutStack: state.layoutStack,
        layout: {
          //...state.layout,
          items: { ...action.layout },
        },
      }
    case ACTIONS.GO_BACK:
      //actionCodes.clearErrorCodes()
      // @ts-ignore
      const { steps, caseId } = state.layout.items
      if (steps) {
        const codesToDelete = []
        steps.forEach((step: { items: any[] }) =>
          step.items.forEach((item) => {
            item.code && codesToDelete.push(item.code)
          })
        )
        if (codesToDelete.length > 0) {
          actionCodes.removeCodesFromCase(caseId, codesToDelete)
        }
        actionCodes.resetAllFields()
      }

      const prevLayout = state.layoutStack[0]
      state.layoutStack.shift()
      return {
        ...state,
        layout: prevLayout,
        layoutStack: state.layoutStack,
      }
    case ACTIONS.SET_THANK_YOU:
      return {
        ...state,
        layout: {
          // @ts-ignore
          items: { ...state.layout.items, steps: [{ ...action.step }] },
        },
        layoutStack: [],
      }
    case ACTIONS.SET_STEP_LAYOUT:
      return {
        ...state,
        // @ts-ignore
        layout: { items: { ...state.layout.items, ...action.stepLayout } },
      }
    default:
      return state
  }
}
