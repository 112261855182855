import styled from 'styled-components'
import {
  FormikErrors,
  FormikProps,
  FormikValues,
  useField,
  useFormikContext,
} from 'formik'
import NumberFormat from 'react-number-format'
import { clearValue } from '../../helpers/form'
import { StyledBaseField } from '../styles'
import { FocusEventHandler } from 'react'

const StyledNumberInput = styled(NumberFormat)`
  ${StyledBaseField}
`
const StyledContainer = styled.div`
  position: relative;
  width: fit-content;
  display: inline-block;

  @media (max-width: 500px) {
    width: 100%;
  }
`

interface NumberFieldProps {
  onBlur: FocusEventHandler
  error: string | FormikErrors<any> | string[] | FormikErrors<any>[]
  id?: string
  hidden?: boolean
  calculated?: boolean
  type?: string
  value?: string | number | readonly string[]
  readOnly?: boolean
  round?: boolean
  label?: string
  description?: string
  options?: any
  formik?: FormikProps<{}>
  name: string
  code?: string
  minDate?: string | Date
  maxDate?: string | Date
  currencySymbol?: string
  minValue?: number
  updateCodes: (name: string, value: string | boolean) => void
}

const NumberField: React.FC<NumberFieldProps> = (props) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  let options = {}

  if (!props.round) {
    options = { ...options, decimalScale: 2, fixedDecimalScale: true }
  } else {
    options = { ...options, decimalScale: 0 }
  }

  if (props.minValue > 0) {
    options = { ...options, allowNegative: false }
  }

  //const { updateCodes } = props

  const value = clearValue(props.value)

  // function handleBlur(e) {
  //   props.onBlur(e)

  //   const filtered = clearValue(e.target.value)
  //   updateCodes(field.name, filtered)
  // }

  return (
    <StyledContainer>
      <StyledNumberInput
        id={props.id}
        name={props.name}
        value={value}
        onBlur={props.onBlur}
        onValueChange={(values: FormikValues) =>
          setFieldValue(field.name, values.formattedValue)
        }
        error={props.error}
        disabled={props.readOnly}
        hidden={props.hidden}
        {...options}
      />
    </StyledContainer>
  )
}

export default NumberField
