import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { authService } from '../services'
import { useStore } from '../context'
import { unsetLayout } from '../context/actionReducer'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { LOCAL_STORAGE_KEYS, ROUTES } from '../constants'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import * as Yup from 'yup'
import {
  StyledFormButton,
  Heading,
  InputFieldContainer,
  Caption,
  StyledForm,
  StyledFlexRow,
  StyledCheckBoxLabel,
  LinkButton,
  StyledLoadingBar,
  StyledFlexColumn,
} from '../components/commonStyles'
import { getErrorMessage, getToken, isLoggedIn, removeToken } from '../helpers'
import { TFunction } from 'i18next'
import { Field, Formik } from 'formik'

interface OtpProps {
  config: any
  t: TFunction
}

const Otp: React.FC<OtpProps> = (props) => {
  const { t, config } = props
  const history = useHistory()

  const { auth } = authService
  if (isLoggedIn(auth)) {
    history.push(ROUTES.DASHBOARD)
  }

  const { state } = useLocation<{ channel?: 'email' | 'phone' }>()
  if (!state) {
    history.push(ROUTES.OTP)
  }

  const ref = React.useRef(null)
  const { addToast } = useMessages()

  const [, dispatch] = useStore()
  React.useEffect(() => {
    dispatch(unsetLayout(true))
  }, [dispatch])

  const logout = () => {
    authService.logout().then(() => history.push(ROUTES.LOGIN))
  }

  const handleSubmit = (values, { setSubmitting }) => {
    ref.current && ref.current.continuousStart()
    authService
      .verifyOtp(values.otp, values.rememberDevice)
      .then(() => {
        setSubmitting(false)
        ref.current && ref.current.complete()
        addToast(MESSAGE_STATUS.SUCCESS, t(['otp.200', '']))
        const redirectUrl = getToken(LOCAL_STORAGE_KEYS.REDIRECT_URL)
        removeToken(LOCAL_STORAGE_KEYS.REDIRECT_URL)
        history.push(redirectUrl || ROUTES.DASHBOARD)
      })
      .catch((e) => {
        setSubmitting(false)
        ref.current && ref.current.complete()
        const errorMessage = getErrorMessage(e, 'otp')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
        if (e?.response?.status === 423) logout()
      })
  }

  const resendOtp = () => {
    authService
      .sendOtp(state.channel)
      .then((res) => {
        addToast(MESSAGE_STATUS.SUCCESS, t('otp.resendSuccess', ''))
      })
      .catch((e) => {
        const errorMessage = getErrorMessage(e, 'mfaChannel')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
  }

  const validationSchema = Yup.object({
    otp: Yup.string().required(t(['forms.requiredField'])),
    rememberDevice: Yup.boolean(),
  })

  return (
    <React.Fragment>
      <StyledLoadingBar
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--elements-loader-pageColor'
        )}
        height={8}
        ref={ref}
      />
      <StyledFlexColumn
        align="center"
        justify="center"
        margin="60px auto"
        padding="0 20px"
      >
        <Heading
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: t('otp.heading', ''),
          }}
        />
        <Caption
          textAlign="center"
          dangerouslySetInnerHTML={{
            __html: t('otp.caption', ''),
          }}
        />
        <Formik
          initialValues={{ otp: '', rememberDevice: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <StyledForm onSubmit={formik.handleSubmit} textAlign="center">
              <InputFieldContainer>
                <InputField
                  id="otp"
                  label={t('otp.codeLabel', '')}
                  autocomplete="one-time-code"
                  type="text"
                  formik={formik}
                  style={{ minWidth: '300px' }}
                />
              </InputFieldContainer>

              {config?.mfaRememberDeviceEnabled && (
                <StyledFlexRow>
                  <Field
                    type="checkbox"
                    id="rememberDevice"
                    name="rememberDevice"
                    checked={formik.values.rememberDevice}
                    style={{
                      accentColor:
                        'var(--pages-mainContainer-primaryTextColor)',
                    }}
                  />
                  <StyledCheckBoxLabel htmlFor="rememberDevice">
                    {t('otp.rememberThis', '')}
                  </StyledCheckBoxLabel>
                </StyledFlexRow>
              )}

              <div>
                <StyledFormButton
                  type="submit"
                  id="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  {formik.isSubmitting ? (
                    <Loader />
                  ) : (
                    <span>{t('otp.otpButton', '')}</span>
                  )}
                </StyledFormButton>
              </div>

              <LinkButton
                id="resend-otp-btn"
                margin="40px 0 0 0"
                className="buttons-linkColor"
                onClick={resendOtp}
              >
                {t('otp.resendCodeButton', '')}
              </LinkButton>
            </StyledForm>
          )}
        </Formik>
      </StyledFlexColumn>
    </React.Fragment>
  )
}

export default withTranslation()(Otp)
