import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { TFunction, withTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { authService } from '../services'
import { useMessages, MESSAGE_STATUS } from '../context/messages'
import { ROUTES, VERIFICATION_CODE_EXPIRATION } from '../constants'
import InputField from '../components/InputField'
import Loader from '../components/Loader'
import {
  StyledFormButton,
  Heading,
  Caption,
  InputFieldContainer,
  LinkButton,
  StyledCounter,
  ErrorMessage,
  StyledColumn,
  StyledGrid,
  StyledWrapper,
  StyledForm,
} from '../components/commonStyles'
import { getErrorMessage } from '../helpers'
import { AxiosError } from 'axios'

interface VerifyProps {
  t: TFunction
}

interface Values {
  code: string
}

const VerifyCode: React.FC<VerifyProps> = ({ t }) => {
  const history = useHistory()
  const { state } = useLocation<{
    accessToken?: string
    maskedPhone?: string
    username: string
  }>()
  const { accessToken, username, maskedPhone } = state

  if (!state || !accessToken || !username) {
    history?.push(ROUTES.REGISTER)
  }

  const codeExpiration = VERIFICATION_CODE_EXPIRATION

  const { addToast } = useMessages()
  const [count, setCount] = useState(codeExpiration)
  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object({
    code: Yup.string().required(t(['forms.requiredField'])),
  })

  const handleSubmit = (
    values: Values,
    {
      setSubmitting,
    }: { setSubmitting: React.Dispatch<React.SetStateAction<boolean>> }
  ) => {
    authService
      .verifyCode(accessToken, values.code)
      .then(() => {
        addToast(MESSAGE_STATUS.SUCCESS, t(['verification.200']))
        history.push(ROUTES.REGISTER_NEW_PSSW)
      })
      .catch((e: AxiosError) => {
        const errorMessage = getErrorMessage(e)
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
      .finally(() => setSubmitting(false))
  }

  const resendCode = () => {
    setLoading(true)
    authService
      .resendCode(accessToken)
      .then(() =>
        addToast(MESSAGE_STATUS.SUCCESS, t(['verification.codeSent']))
      )
      .catch((e: AxiosError) => {
        const errorMessage = getErrorMessage(e, 'verification')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
      .finally(() => setLoading(false))
  }

  const newCode = () => {
    setLoading(true)
    authService
      .register(username)
      .then(() => {
        addToast(MESSAGE_STATUS.SUCCESS, t(['verification.newCodeSent']))
        setCount(codeExpiration)
      })
      .catch((e: AxiosError) => {
        const errorMessage = getErrorMessage(e, 'verification')
        addToast(MESSAGE_STATUS.ERROR, errorMessage)
      })
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    count > 0 && setTimeout(() => setCount(count - 1), 1000)
  }, [count])

  return (
    <StyledWrapper align="center" margin="125px auto">
      <StyledGrid halign="center">
        <StyledColumn
          size={{ md: 4 / 8, lg: 3 / 12 }}
          halign="center"
          direction="column"
        >
          <Heading
            dangerouslySetInnerHTML={{
              __html: t('verification.heading', ''),
            }}
          />
          <Caption
            dangerouslySetInnerHTML={{
              __html: t('verification.caption', { maskedPhone }),
            }}
          />
          {count ? (
            <React.Fragment>
              <StyledCounter id="counter">
                {new Date(count * 1000).toISOString().substr(14, 5)}
              </StyledCounter>

              <Formik
                initialValues={{ code: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <StyledForm onSubmit={formik.handleSubmit}>
                    <InputFieldContainer>
                      <InputField
                        id="code"
                        label={t('verification.codeLabel', '')}
                        type="text"
                        formik={formik}
                        style={{ minWidth: '300px' }}
                      />
                    </InputFieldContainer>

                    <div>
                      <StyledFormButton
                        type="submit"
                        id="submit"
                        disabled={!(formik.isValid && formik.dirty)}
                      >
                        {formik.isSubmitting ? (
                          <Loader />
                        ) : (
                          <span>
                            {t('verification.verificationButton', '')}
                          </span>
                        )}
                      </StyledFormButton>
                    </div>

                    {loading && <Loader />}
                    {/* {message && (
                      <ErrorMessage id="error-message">{message}</ErrorMessage>
                    )} */}
                  </StyledForm>
                )}
              </Formik>

              <LinkButton
                onClick={resendCode}
                className="buttons-linkColor"
                margin="40px 0 0 0"
                id="resend-code"
              >
                {t('verification.resendCodeText', '')}
              </LinkButton>
              <Caption>{t('verification.bottomText', '')}</Caption>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ErrorMessage id="code-expired-message">
                {t('verification.codeExpirationText', '')}
              </ErrorMessage>
              <StyledFormButton onClick={newCode} id="new-code-btn">
                {loading ? (
                  <Loader />
                ) : (
                  <span>{t('verification.sendNewCodeText', '')}</span>
                )}
              </StyledFormButton>
            </React.Fragment>
          )}
        </StyledColumn>
      </StyledGrid>
    </StyledWrapper>
  )
}

export default withTranslation()(VerifyCode)
