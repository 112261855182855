import React from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Dashboard from './Dashboard'
import { useStore } from '../context'
import useLoadActions from '../helpers/useLoadActions'

interface Props {
  t: TFunction
}

const Callback: React.FC<Props> = ({ t }) => {
  const { pathId } = useParams<{ pathId?: string }>()
  const [state] = useStore()

  const { callback } = state?.layout
  const actions = callback?.find((item) => item?.path === pathId)?.loadActions

  useLoadActions(actions)

  return <Dashboard pathId={pathId} />
}

export default withTranslation()(Callback)
